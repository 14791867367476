/**
 * Created by LifeSoft on 28/11/17.
 */
import {Injectable} from '@angular/core';
import {MainResponse} from './response/main-response';
import {SubjectTopic} from './subject-topic';
import {Student} from './student';
@Injectable()
export class Subject {
    public id: number;
    public code: string;
    public name: string;
    public position: number;
    public elective: boolean;
    public abv?: string;
    public subjectTopics?: SubjectTopic[];
    public pivot?: any;
}
@Injectable()
export class SubjectResponse extends MainResponse {
    data: SubjectResponseData|null;
}
@Injectable()
export class SubjectResponseData {
    subject: Subject;
}
@Injectable()
export class SubjectsResponse extends MainResponse {
    data: SubjectsResponseData|null;
}
@Injectable()
export class SubjectsResponseData {
    subjects: Subject[];
}
@Injectable()
export class SubjectBody {
    public code: string;
    public name: string;
    public position: number;
    public elective: boolean;
    public abv: string;
}
@Injectable()
export class AssignSubjectTeacherBody {
    public employee_id: number;
    public subjects: number[] = [];
    public edu_class_id: number;
}
@Injectable()
export class AssignSubjectStudentBody {
    public student_id: number;
    public subjects: number[] = [];
    public academic_year_id: number;
    public term_id: number;
}
@Injectable()
export class AssignSubjectClassBody {
    public edu_class_id: number;
    public subjects: number[] = [];
    public assign_to_students: boolean;
    public academic_year_id: number;
    public term_id: number;
}
@Injectable()
export class AssignStudentElectiveSubjectBody {
  public student_id: number;
  public subject_id: number;
  public add: boolean;
  public hasSubject: boolean;
}

@Injectable()
export class classElectiveSubjects extends MainResponse {
  public data: classElectiveSubjectsData | null;
}

@Injectable()
export class classElectiveSubjectsData extends MainResponse {
  public students: Student[];
  public subjects: Subject[];
}
